import React from "react";
import { formatMonto } from "../../utils";
import moment from "moment";
import { Link } from "@reach/router";

const SingleClassRatingCard = ({ rating }) => {
  const renderRating = () => {
    let components = [];
    if (rating.class_stars !== null) {
      components.push(
        <span key="class" className="me-3">
          <div>
            {formatMonto(rating.class_stars)}{" "}
            <i className="fa text-warning fa-star ms-1"></i>
          </div>
          <p style={{ fontSize: '12px'}} className="mb-0">Clase</p>
        </span>
      );
    }
    if (rating.coach_stars !== null) {
      components.push(
        <span key="coach">
          <div>
            {formatMonto(rating.coach_stars)}
            <i className="fa text-warning fa-star ms-1"></i>
          </div>
          <p style={{ fontSize: '12px'}} className="mb-0">Clase</p>
        </span>
      );
    }
    if (components.length === 0) {
      components.push(
        <span key="none" className="small text-muted">
          Sin calificar
        </span>
      );
    }
    return <div className="mb-2 d-flex">{components}</div>;
  };

  const renderComment = () => {
    if (rating.comment === null) {
      return <p className="text-muted small">Sin comentario</p>;
    }
    return <p>{rating.comment}</p>;
  };

  const renderClassType = (single_class) => {
    const { class_type } = single_class;
    if (class_type && class_type !== null) {
      return <p className="small bold mb-0">{single_class.class_type.name} </p>;
    }
  };

  const renderInstructors = (single_class) => {
    return single_class.class_instructors
      .filter(({ instructor }) => instructor !== null)
      .map((class_instructor) => (
        <span
          className="badge badge-pill me-1 bg-secondary"
          key={class_instructor.instructor_id}
        >
          {class_instructor.instructor.name}
        </span>
      ));
  };

  const renderSingleClass = () => {
    const { single_class } = rating;
    if (single_class && single_class !== null) {
      return (
        <div className="reply px-md-4 px-3 bg-white br-15 text-dark my-2">
          {renderClassType(single_class)}
          <div className="row">
            <div className="col-12 col-md-6">
              {renderInstructors(single_class)}
            </div>
            <div className="col-12 col-md-6">
              <p className="small mb-0">
                {moment(single_class.class_date)
                  .utc()
                  .format("DD MMM YYYY HH:mm")}
              </p>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="card no-scale shadow mb-3">
      <Link
        to={`/myadmin/customer/${rating.customer_id}`}
        className="mb-1 text-accent"
      >
        <i className="fa fa-user me-1" /> {rating.customer.name}{" "}
        {rating.customer.last_name}
      </Link>
      {renderSingleClass()}
      {renderComment()}
      {renderRating()}
    </div>
  );
};

export default SingleClassRatingCard;
