import React, { useContext, useEffect, useState } from "react";
import PanelTitle from "../../components/global/PanelTitle";
import { grayImg } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";
import useCrudPopups from "../../hooks/ajustes/useCrudPopups";

const AdminAjustes = () => {
  const [currentTab, setCurrentTab] = useState("online");

  const [onlineFile, setOnlineFile] = useState("");
  const [onlineFileChanged, setOnlineFileChanged] = useState(false);

  const [presencialFileChanged, setPresencialFileChanged] = useState(false);
  const [presencialFile, setPresencialFile] = useState("");

  const {
    online_popup_src,
    studio_popup_src,
    getAppConfig,
    setAppConfigProperty,
  } = useContext(AppConfigContext);

  const { updatePopupFile } = useCrudPopups();

  useEffect(() => {
    getAppConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    const uploadPromises = [];

    if (onlineFileChanged) {
      uploadPromises.push(
        await updatePopupFile("online_popup_src", onlineFile)
      );
    }

    if (presencialFileChanged) {
      uploadPromises.push(
        await updatePopupFile("studio_popup_src", presencialFile)
      );
    }

    await Promise.all(uploadPromises);
  };

  const renderTabs = () => {
    const tabs = [
      { label: "Online", value: "online" },
      { label: "Presencial", value: "presencial" },
    ];

    return tabs.map((tab) => {
      const active = tab.value === currentTab ? true : false;

      return (
        <li
          key={tab.value}
          className={`nav-item customer-nav-item`}
          onClick={() => setCurrentTab(tab.value)}
        >
          <p
            className={`nav-link mb-0 ${
              active ? "bg-primary text-white" : "text-dark"
            } `}
          >
            {tab.label}
          </p>
        </li>
      );
    });
  };

  const renderOnlineConfig = () => {
    if (currentTab === "online") {
      return (
        <div className="d-flex flex-column w-100 p-0 justify-content-between align-items-center ">
          <h3>Imagen para popup en Online</h3>
          <img
            src={online_popup_src}
            alt="popup online image"
            className="bg-gray mb-3"
            style={{
              width: "500px",
              height: "auto",
              objectFit: "contain",
            }}
          />
          <input
            type="file"
            className="form-control"
            style={{ width: "max-content" }}
            onChange={(event) => {
              const reader = new FileReader();
              const image = event.target.files[0];

              if (image) {
                reader.onload = (e) => {
                  const src = e.target.result;
                  setOnlineFile(image);
                  setOnlineFileChanged(true);
                  setAppConfigProperty("online_popup_src", src);
                };

                reader.readAsDataURL(image);
              }
            }}
          />
        </div>
      );
    }
  };

  const renderPresencialConfig = () => {
    if (currentTab === "presencial") {
      return (
        <div className="d-flex flex-column w-100 p-0 justify-content-between align-items-center ">
          <h3>Imagen para popup en Presenciales</h3>
          <img
            src={studio_popup_src}
            alt="popup presencial image"
            className="bg-gray mb-3"
            style={{
              width: "500px",
              height: "auto",
              objectFit: "contain",
            }}
          />
          <input
            type="file"
            className="form-control"
            style={{ width: "max-content" }}
            onChange={(event) => {
              const reader = new FileReader();
              const image = event.target.files[0];

              if (image) {
                reader.onload = (e) => {
                  const src = e.target.result;
                  setPresencialFile(image);
                  setPresencialFileChanged(true);
                  setAppConfigProperty("studio_popup_src", src);
                };

                reader.readAsDataURL(image);
              }
            }}
          />
        </div>
      );
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitle title="Ajustes" hideButton />
      <div className="card p-3 shadow">
        <div className="row mb-3 align-items-center">
          <div className="col-6">
            <ul
              className="nav nav-pills d-inline-flex me-3"
              style={{ width: "max-content" }}
            >
              {renderTabs()}
            </ul>
          </div>
        </div>

        <div className="row w-100 mx-auto">
          {renderOnlineConfig()}
          {renderPresencialConfig()}
        </div>

        <div className="row w-100 mx-auto justify-content-end">
          <button
            onClick={handleSave}
            type="button"
            className="btn btn-primary"
            style={{ width: "max-content" }}
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};
export default AdminAjustes;
