import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import moment from "moment";
import momentTz from "moment-timezone";
import { ModalContext } from "../../context/ModalContext";
import { BranchesContext } from "../../context/BranchesContext";

const OnlineAccessForm = ({ online_access }) => {
  const [access, setAccess] = useState(online_access);
  const [selectedTimeZone, setSelectedTimeZone] = useState('');

  const { saveCustomerOnlineAccess, customer } = useContext(CustomerContext);
  const { branches, branch} = useContext(BranchesContext);
  const { clearModal } = useContext(ModalContext);

  useEffect(() => {
    setAccess(online_access);
  }, [online_access]);

  useEffect(() => {
    setSelectedTimeZone(branch.time_zone);
  }, [branch]);



  const handleChangeAccess = (key) => {
    return (event) => {
      let currentValue = event.target.value;
      if (key === "start_date") {
        currentValue = moment(currentValue).startOf("day").format();
      }

      if (key === "expiration_date") {
        currentValue = moment(currentValue).endOf("day").format();
      }

      setAccess({
        ...access,
        [key]: currentValue,
      });
    };
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const start_date = momentTz(access.start_date).tz(selectedTimeZone, true).format();
    const expiration_date = momentTz(access.expiration_date).tz(selectedTimeZone, true).format();
    
    saveCustomerOnlineAccess({
      ...access,
      start_date,
      expiration_date
    });
  };

  const renderPurchasesOpts = () => {
    if (customer && customer !== null) {
      return customer?.purchases?.map((current) => {
        const purchase_id = current.purchase_id;
        const packageName = current.class_package?.title;

        return (
          <option key={purchase_id} value={purchase_id}>
            #{purchase_id} - {packageName}
          </option>
        );
      });
    }
  };

  const renderInvoicesOpts = () => {
    if (customer && customer !== null) {
      return customer.invoices?.map((current) => {
        const invoice_id = current.invoice_id;
        const purchaseSelected = !isNaN(parseInt(access.purchase_id));
        const currentInvoiceIsPurchaseChild =
          String(current.purchase_id) === String(access.purchase_id);

        if (
          (purchaseSelected && currentInvoiceIsPurchaseChild) ||
          !purchaseSelected
        ) {
          return (
            <option key={invoice_id} value={invoice_id}>
              #{invoice_id}
            </option>
          );
        } else {
          return null;
        }
      });
    }
  };

  const renderTimeZoneOptions = () => {
    if (Array.isArray(branches)) {
      return branches.map((current) => {
        return (
          <option key={current.branch_id} value={current.time_zone}>
            {current.name}
          </option>
        );
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="">
      <label>Estatus</label>
      <select
        value={access.status}
        className="form-control mb-3"
        onChange={handleChangeAccess("status")}
      >
        <option value={"active"}>Activo</option>
        <option value={"paused"}>Pausado</option>
        <option value={"revoked"}>Revocado</option>
      </select>

      <label>Zona Horaria</label>
      <select
        value={selectedTimeZone}
        className="form-control mb-3"
        onChange={(event) => setSelectedTimeZone(event.target.value)}
      >
        {renderTimeZoneOptions()}
      </select>

      <div className="row">
        <div className="col-6">
          <label>#Compra</label>
          <select
            value={access.purchase_id}
            className="form-control mb-3"
            onChange={handleChangeAccess("purchase_id")}
          >
            <option value={""}>Seleccionar Compra</option>
            {renderPurchasesOpts()}
          </select>
        </div>

        <div className="col-6">
          <label>#Cargo</label>
          <select
            value={access.invoice_id}
            className="form-control mb-3"
            onChange={handleChangeAccess("invoice_id")}
          >
            <option value={""}>Seleccionar Cargo</option>
            {renderInvoicesOpts()}
          </select>
        </div>
      </div>

      <label>Fecha de Inicio</label>
      <input
        type="date"
        value={moment(access.start_date).format("YYYY-MM-DD")}
        className="form-control mb-3"
        onChange={handleChangeAccess("start_date")}
      />

      <label>Fecha de Expiración</label>
      <input
        type="date"
        value={moment(access.expiration_date).format("YYYY-MM-DD")}
        className="form-control mb-3"
        onChange={handleChangeAccess("expiration_date")}
      />

      <div className="row mt-5">
        <div className="col-6 ">
          <button
            type="button"
            className="btn w-100 text-muted capitalize"
            onClick={clearModal}
          >
            CANCELAR
          </button>
        </div>
        <div className="col-6">
          <button className="btn btn-primary w-100">Guardar</button>
        </div>
      </div>
    </form>
  );
};

export default OnlineAccessForm;
